import React, { createContext, useContext, useState } from 'react';

import { AUTHORIZED } from '../constants/constants';

export interface AuthContextProps {
  isAuthenticated: boolean;
  token: string | null;
  setToken: (token: string | null) => void;
  getToken: () => string | null;
  setAuthorized: (authorized: boolean) => void;
}

export const initialState = {
  isAuthenticated: false,
  setToken: () => {},
  token: null,
  getToken: () => null,
  setAuthorized: () => {},
};

const getTokenFormStorage = () => {
  return localStorage.getItem(AUTHORIZED) ?? 'false';
};

const AuthContext = createContext<AuthContextProps>(initialState);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthorized, setAuthorized] = useState<boolean>(
    () => getTokenFormStorage() === 'true',
  );
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthorized,
        token: '',
        setToken: () => {},
        setAuthorized,
        getToken: null,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export default AuthProvider;
