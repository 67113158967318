import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Checkbox } from '@incodetech/ui';

interface AuthConfigurationMultipleChecksProps {
  name: string;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
}

const AuthConfigurationMultipleChecks = ({
  name,
  disabled,
  required = false,
  errorMessage = '',
}: AuthConfigurationMultipleChecksProps) => {
  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const error = (errors ?? {})[name];
  const values = watch(name);
  const { fields } = useFieldArray({
    control,
    name,
  });

  const hasChecked = (values ?? []).some(
    (v: { checked: boolean }) => v.checked,
  );

  useEffect(() => {
    if (required && !hasChecked) {
      setError(name, {
        type: 'required',
        message: errorMessage,
      });
    } else {
      clearErrors(name);
    }
  }, [hasChecked, setError, required, errorMessage, clearErrors, name]);

  return (
    <>
      {fields.map((field, index) => {
        const fieldValue = watch(`${name}.${index}`);
        return (
          <Checkbox
            key={field.id}
            id={fieldValue.name}
            name={fieldValue.name}
            label={fieldValue.name}
            checked={fieldValue.checked}
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue(`${name}.${index}`, {
                ...fieldValue,
                checked: e.target.checked,
              });
            }}
          />
        );
      })}
      {error && error.message && isSubmitted && (
        <div className="error">{error.message as string}</div>
      )}
    </>
  );
};

export default AuthConfigurationMultipleChecks;
