import axios from 'axios';

import { ORG_ID, AUTHORIZED, CLIENT_ID } from '../constants/constants';

const client = axios.create({
  baseURL: '/'
});

client.interceptors.response.use(response => response,
  error => {
    const status = error.response ? error.response.status : null;

    if (status === 401 || status === 403) {
      localStorage.removeItem(AUTHORIZED);
      localStorage.removeItem(ORG_ID);
      localStorage.removeItem(CLIENT_ID);
      window.location.href = '/log-in';
    }

    return Promise.reject(error);
  });

export { client };
