import React, { useEffect } from 'react';
import {
  createRootRouteWithContext,
  createRoute,
  Outlet,
  redirect,
  createRouter,
  useNavigate,
  ScrollRestoration,
} from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';

import { AuthContext, initialState, useAuth } from '../providers';
import { AUTHORIZED } from '../constants/constants';
import { Login } from '../views/Login';
import { Authentication } from '../views/Authentication';
import { Layout } from '../components/Layuout';
import TrustedOrigins from '../views/TrustedOrigins';
import { ClientRegistration } from '../views/ClientRegistration';

interface RouterContext {
  auth: AuthContext;
}

const redirectIfNoSession = (auth: AuthContext, location) => {
  if (!auth.isAuthenticated) {
    throw redirect({
      to: '/log-in',
      search: {
        redirect: location.href,
      },
    });
  }
};

const rootRoute = createRootRouteWithContext<RouterContext>()({
  component: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
      return <Outlet />;
    }

    return (
      <>
        <ScrollRestoration />
        <Layout>
          <Outlet />
          <TanStackRouterDevtools />
        </Layout>
      </>
    );
  },
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  beforeLoad: ({ context, location }) =>
    redirectIfNoSession(context.auth, location),
  component: Authentication,
});

const login = createRoute({
  getParentRoute: () => rootRoute,
  path: '/log-in',
  beforeLoad: ({ context }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: '/' });
    }
  },
  component: Login,
});

const Authorized = () => {
  const { setAuthorized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setAuthorized(true);
    setTimeout(() => navigate({ to: '/' }), 100);
  }, [setAuthorized, navigate]);

  return <>Loading ...</>;
};

const autorized = createRoute({
  getParentRoute: () => rootRoute,
  path: '/authorized',
  validateSearch: (search: Record<string, unknown>) => {
    return {
      authorized: (search.authorized as boolean) ?? false,
    };
  },
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: '/' });
    }

    if (!search.authorized) {
      throw redirect({ to: '/log-in' });
    }

    localStorage.setItem(AUTHORIZED, 'true');
  },
  component: Authorized,
});

const trustedOrigins = createRoute({
  getParentRoute: () => rootRoute,
  path: '/trusted-origins',
  beforeLoad: ({ context, location }) =>
    redirectIfNoSession(context.auth, location),
  component: TrustedOrigins,
});

const clientRegistration = createRoute({
  getParentRoute: () => rootRoute,
  path: '/client-registration',
  beforeLoad: ({ context, location }) =>
    redirectIfNoSession(context.auth, location),
  component: ClientRegistration,
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  trustedOrigins,
  login,
  autorized,
  clientRegistration,
]);

const router = createRouter({
  routeTree,
  context: {
    auth: initialState,
  },
});

export default router;
