import { RefObject, useEffect, useRef } from 'react';

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
): void {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
    };

    // Reload only if ref or handler changes
  }, [ref, handler]);
}

export const useEventListener = (eventName, handler, element = null) => {
  /**
   * Once ts is implemented we can set this back to:
   * const useEventListener = (eventName, handler, element = global)
   */
  if (!element) {
    element = global;
  }
  const savedHandler = useRef(null);
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = event => savedHandler.current!(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
