import { useQuery } from '@tanstack/react-query';

import { client } from '../http/api';
import { AuthClientConfigResponse } from '../types/authClientConfig';

async function getClients() {
  const response = await client.get<AuthClientConfigResponse>(`/auth/client/all`, {
    withCredentials: true,
  });
  return response.data;
}

function useGetClients() {
  return useQuery({
    queryKey: ['Clients'],
    queryFn: getClients,
    retry: 1,
  });
}

export { useGetClients };
export default useGetClients;
