import React, { useMemo } from 'react';
import {
  ModalDialog,
  Button,
  Select,
  Input,
  Checkbox,
  NotificationBox,
} from '@incodetech/ui';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AuthClientGrantTypes,
  AuthClientMethods,
  AuthClientScopes,
  ClientRegistration,
} from '../../../types/clientRegistration';

import './ClientConfigurationModal.scss';

interface ClientConfigurationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleFormSubmit: SubmitHandler<ClientRegistration>;
  formData: ClientRegistration;
  editing: boolean;
  serverError: string;
}

const defaulFormValue: ClientRegistration = {
  registrationId: '',
  clientId: '',
  clientSecret: '',
  clientAuthenticationMethod: AuthClientMethods.CLIENT_SECRET_BASIC,
  authorizationGrantType: AuthClientGrantTypes.AUTHORIZATION_CODE,
  redirectUri: '',
  scopes: [],
  providerDetails: {
    authorizationUri: '',
    tokenUri: '',
    jwkSetUri: '',
    issuerUri: '',
    userInfoUri: '',
    userNameAttributeName: '',
  },
  clientName: '',
};

export const ClientConfigurationModal: React.FC<
  ClientConfigurationModalProps
> = ({
  isOpen,
  onRequestClose,
  handleFormSubmit,
  formData,
  editing,
  serverError,
}) => {
  const { t } = useTranslation();
  const methods = useForm<ClientRegistration>({
    values: { ...defaulFormValue, ...formData },
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = methods;

  const handleChangeScopes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (checked) {
      setValue('scopes', [...watch('scopes'), name as AuthClientScopes]);
    } else {
      setValue(
        'scopes',
        watch('scopes')?.filter(item => item !== name),
      );
    }
  };

  const afterClose = () => {
    reset();
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      closeModal={onRequestClose}
      afterModalClose={afterClose}
      shouldCloseOnOverlayClick={false}
      modalTitle={t('authConfiguration.form.title')}
      classes="client-configuration-modal"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(data => {
            handleFormSubmit(data);
            clearErrors();
          })}
          className="configuration-form"
        >
          <div className="auth-configuration-content">
            <div className="input-wrapper">
              <Input
                id="name"
                label={t('authConfiguration.form.name')}
                variant="full"
                {...register('clientName', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.clientName?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="clientId"
                label={t('clientRegistration.form.clientId')}
                variant="full"
                {...register('clientId', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.clientId?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="clientSecret"
                label={t('clientRegistration.form.clientSecret')}
                variant="full"
                {...register('clientSecret', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.clientSecret?.message}
              />
            </div>
            <div className="input-wrapper">
              <Select
                id="clientAuthenticationMethod"
                {...register('clientAuthenticationMethod', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                variant="default"
                error={errors?.clientAuthenticationMethod?.message}
                label={t('clientRegistration.form.clientAuthenticationMethod')}
                options={Object.values(AuthClientMethods).map(method => ({
                  value: method,
                  label: method,
                }))}
              />
            </div>
            <div className="input-wrapper">
              <Select
                id="authorizationGrantType"
                {...register('authorizationGrantType', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                variant="default"
                error={errors?.authorizationGrantType?.message}
                label={t('clientRegistration.form.authorizationGrantType')}
                options={Object.values(AuthClientGrantTypes).map(method => ({
                  value: method,
                  label: method,
                }))}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="redirectUri"
                label={t('clientRegistration.form.redirectUri')}
                variant="full"
                {...register('redirectUri', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.redirectUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <label className="input-label">
                {t('authConfiguration.form.scopes')}
              </label>
              {['openid', 'profile', 'email', 'address', 'phone'].map(item => (
                <div className="input-group" key={item}>
                  <Checkbox
                    label={item}
                    id={item}
                    name={item}
                    checked={
                      watch('scopes')?.includes(item as AuthClientScopes) ||
                      item === 'openid'
                    }
                    disabled={item === 'openid'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeScopes(e);
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.tokenUri"
                label={t('clientRegistration.form.tokenUri')}
                variant="full"
                {...register('providerDetails.tokenUri', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.providerDetails?.tokenUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.jwkSetUri"
                label={t('clientRegistration.form.jwkSetUri')}
                variant="full"
                {...register('providerDetails.jwkSetUri', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.providerDetails?.jwkSetUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.authorizationUri"
                label={t('clientRegistration.form.authorizationUri')}
                variant="full"
                {...register('providerDetails.authorizationUri', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.providerDetails?.authorizationUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.issuerUri"
                label={t('clientRegistration.form.issuerUri')}
                variant="full"
                {...register('providerDetails.issuerUri', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.providerDetails?.issuerUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.userInfoUri"
                label={t('clientRegistration.form.userInfoUri')}
                variant="full"
                {...register('providerDetails.userInfoUri', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.providerDetails?.userInfoUri?.message}
              />
            </div>
            <div className="input-wrapper">
              <Input
                id="providerDetails.userNameAttributeName"
                label={t('clientRegistration.form.userNameAttributeName')}
                variant="full"
                {...register('providerDetails.userNameAttributeName', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                error={errors.providerDetails?.userNameAttributeName?.message}
              />
            </div>
          </div>
          {serverError && (
            <NotificationBox variant="error" text={serverError} />
          )}

          <div className="separator" />
          <div className="button-container">
            <Button
              label={t('authConfiguration.form.buttonSave')}
              type="submit"
              variant="blue"
              classes="modal-button"
            />
          </div>
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default ClientConfigurationModal;
