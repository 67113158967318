import { useMutation, useQueryClient } from '@tanstack/react-query';

import { client } from '../http/api';
import { TrustedOrigins } from '../types/trustedOrigins';

async function updateTrustedOrigin(data) {
  const response = await client.put<TrustedOrigins>(`/auth/origin`, data, {
    withCredentials: true,
  });
  return response.data;
}

function useUpdateTrustedOrigin() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: updateTrustedOrigin,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['TrustedOrigins'] });
    },
  });
}

export { useUpdateTrustedOrigin };
export default useUpdateTrustedOrigin;
