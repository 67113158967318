import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// @ts-ignore
import esTranslations from './locales/es.json';
// @ts-ignore
import enTranslations from './locales/en.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { camelToStartCase } from './utils/utils';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: enTranslations,
  },
  es: {
    translation: esTranslations,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    resources,
    detection: {
      lookupQuerystring: 'lang',
    },
    parseMissingKeyHandler: key => {
      const splitKey = key.split('.');
      const missingKey = splitKey[splitKey.length - 1];
      return camelToStartCase(missingKey);
    },
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
