import React from 'react';
import { ModalConfirm, Text } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleDelete: () => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  isOpen,
  closeModal,
  handleDelete,
}) => {
  const { t } = useTranslation();
  return (
    <ModalConfirm
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={t('authConfiguration.modalDelete.title')}
      handleConfirm={handleDelete}
      denyButtonLabel={t('authConfiguration.modalDelete.denyButton')}
      confirmButtonLabel={t('authConfiguration.modalDelete.confirmButton')}
      isDestructive
    >
      <Text tag="p">{t('authConfiguration.modalDelete.text')}</Text>
    </ModalConfirm>
  );
};

export default ConfirmDeleteModal;
