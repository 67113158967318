import { useMutation, useQueryClient } from '@tanstack/react-query';

import { client } from '../http/api';
import { AuthClientConfig } from '../types/authClientConfig';

async function updateClient(data) {
  const response = await client.put<AuthClientConfig>(`/auth/registration`, data, {
    withCredentials: true,
  });
  return response.data;
}

function useUpdateClientRegistration() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: updateClient,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['ClientRegistration'] });
    },
  });
}

export { useUpdateClientRegistration };
export default useUpdateClientRegistration;
