import { useMutation, useQueryClient } from '@tanstack/react-query';

import { client } from '../http/api';

async function reenerateSecret({ clientId }: { clientId: string }) {
  const response = await client.post<{ clientSecret: string }>(`/auth/client/secret/regenerate-secret`, { clientId }, {
    withCredentials: true,
  });
  return response.data;
}

function useRegenerateSecret() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: reenerateSecret,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['Clients'] });
    },
  });
}

export { useRegenerateSecret };
export default useRegenerateSecret;
