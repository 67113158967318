import React from 'react';
import {
  ModalDialog,
  Text,
  ButtonIcon,
  ButtonIconVariant,
  Tooltip,
} from '@incodetech/ui';
import { useTranslation } from 'react-i18next';

import { useCopyToClipBoard } from '../../../hooks/useCopyToClipboard';
import { GeneratedDataModal as GeneratedDataModalType } from '../../../types/authClientConfig';

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  closeModal: () => void;
  info: GeneratedDataModalType['info'];
}

const GeneratedDataModal: React.FC<ConfirmDeleteModalProps> = ({
  isOpen,
  closeModal,
  info,
}) => {
  const { t } = useTranslation();
  const { copied, handleCopy } = useCopyToClipBoard();

  return (
    <ModalDialog
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={t('authConfiguration.dataModal.title')}
      classes="generated-data-modal"
    >
      <Text tag="p">{t('authConfiguration.dataModal.message')}</Text>
      {info.clientId ? (
        <Text tag="p">
          <Text tag="span">{t('authConfiguration.dataModal.clientId')}</Text>
          <div className="data-to-copy">
            <Text tag="span" classes="generated">
              {info.clientId}
            </Text>
            <Tooltip
              content={
                copied?.clientId
                  ? t('authConfiguration.notifications.copiedClientId')
                  : t('authConfiguration.notifications.copyClientId')
              }
            >
              <ButtonIcon
                variant={ButtonIconVariant.COPY}
                onClick={() => handleCopy(info.clientId, 'clientId')}
              />
            </Tooltip>
          </div>
        </Text>
      ) : null}
      <Text tag="p">
        <Text tag="span">{t('authConfiguration.dataModal.clientSecret')}</Text>
        <div className="data-to-copy">
          <Text tag="span" classes="generated">
            {info.clientSecret}
          </Text>
          <Tooltip
            content={
              copied?.clientSecret
                ? t('authConfiguration.notifications.copiedClientSecret')
                : t('authConfiguration.notifications.copyClientSecret')
            }
          >
            <ButtonIcon
              variant={ButtonIconVariant.COPY}
              onClick={() => handleCopy(info.clientSecret, 'clientSecret')}
            />
          </Tooltip>
        </div>
      </Text>
    </ModalDialog>
  );
};

export default GeneratedDataModal;
