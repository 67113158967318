import { useMutation, useQueryClient } from '@tanstack/react-query';

import { client } from '../http/api';
import { AuthClientConfig } from '../types/authClientConfig';

async function createClient(data) {
  const response = await client.post<AuthClientConfig>(`/auth/client`, data, {
    withCredentials: true,
  });
  return response.data;
}

function useCreateClient() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: createClient,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['Clients'] });
    },
  });
}

export { useCreateClient };
export default useCreateClient;
