import { useQuery } from '@tanstack/react-query';

import { client } from '../http/api';
import { ClientRegistrationResponse } from '../types/clientRegistration';

async function getClientRegistrations() {
  const response = await client.get<ClientRegistrationResponse>(`/auth/registration/all`, {
    withCredentials: true,
  });
  return response.data;
}

function useGetClientRegistrations() {
  return useQuery({
    queryKey: ['ClientRegistration'],
    queryFn: getClientRegistrations,
    retry: 1,
  });
}

export { useGetClientRegistrations };
export default useGetClientRegistrations;
