import { useState } from 'react';

type CopiedItem = Record<string, boolean>;

export const fallbackCopyTextToClipboard = (text: string): void => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) { }
  document.body.removeChild(textArea);
};

export const copyToClipboard = (text: string): Promise<void> | void => {
  return navigator.clipboard
    ? navigator.clipboard.writeText(text)
    : fallbackCopyTextToClipboard(text);
};

export const useCopyToClipBoard = () => {
  const [copied, setCopied] = useState<CopiedItem>({});
  const handleCopy = (value: string, field: string) => {
    setCopied({
      [field]: true,
    });
    copyToClipboard(value);
    setTimeout(() => {
      setCopied({ [field]: false });
    }, 1000);
  };

  return {
    copied,
    handleCopy,
  };
};
