import React, { useState } from 'react';
import { Card, ButtonIcon, ButtonIconVariant, Button } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';

import {
  useGetClients,
  useCreateClient,
  useUpdateClient,
  useDeleteClient,
  useRegenerateSecret,
} from '../../hooks';
import { hasConfiguration } from '../../utils/authClientConfigUtils';
import {
  AuthClientSettingsKey,
  AuthClientSettingsKeys,
  AuthClientScopesOrder,
  ModalConfigData,
  ModalDeleteData,
  GeneratedDataModal as GeneratedDataModalType,
  ModalConfigFormData,
  AuthClientScopes,
  AuthClientGrantTypes,
  AuthClientMethods,
} from '../../types/authClientConfig';
import { ErrorCode, ORG_ID } from '../../constants/constants';
import AuthConfigurationModal from './components/AuthConfigurationModal';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import GeneratedDataModal from './components/GeneratedDataModal';

import './Authentication.scss';

function Authentication() {
  const orgId = localStorage.getItem(ORG_ID);
  const { t } = useTranslation();
  const [modalData, setModalData] = useState<ModalConfigData>({
    isOpen: false,
    formData: {} as ModalConfigFormData,
    editing: false,
  });
  const [deleteModalState, setDeleteModalState] = useState<ModalDeleteData>({
    isOpen: false,
    clientId: '',
  });
  const [generatedDataModal, setGeneratedDataModal] =
    useState<GeneratedDataModalType>({
      isOpen: false,
      info: { clientId: '', clientSecret: '' },
    });
  const [serverError, setServerError] = useState('');
  const { data } = useGetClients();
  const { mutateAsync: addAuthConfig } = useCreateClient();
  const { mutateAsync: editAuthConfig } = useUpdateClient();
  const { mutateAsync: deleteConfig } = useDeleteClient();
  const { mutateAsync: regenerateSecret, isPending } = useRegenerateSecret();

  const submitAuthConfigModal = async (data: ModalConfigFormData) => {
    try {
      setServerError('');
      const { issuerUrl, authorizeUrl, tokenUrl, ...restData } = data;
      const submitData = {
        ...restData,
        scopes: [...data.scopes, AuthClientScopes.OPEN_ID],
        authorizationGrantTypes: [
          ...data.authorizationGrantTypes,
          AuthClientGrantTypes.AUTHORIZATION_CODE,
        ],
        clientAuthenticationMethods: data.clientAuthenticationMethods
          .filter(d => d.checked)
          .map(m => m.value),
        redirectUris: data.redirectUris.map(item => item.value),
        postLogoutRedirectUris: data.postLogoutRedirectUris?.map(
          item => item.value,
        ),
        clientOrganization: orgId,
      };

      const config = modalData.editing
        ? await editAuthConfig(submitData)
        : await addAuthConfig(submitData);

      if (!modalData.editing) {
        setGeneratedDataModal({
          isOpen: true,
          info: {
            clientId: config.clientId,
            clientSecret: config.clientSecret,
          },
        });
      }

      setModalData({
        isOpen: false,
        formData: {} as ModalConfigFormData,
        editing: false,
      });
    } catch (e) {
      let errorMessage = t('authConfiguration.error');

      if (e?.response?.data?.status === ErrorCode.NON_UNIQUE_CLIENT_NAME) {
        errorMessage = t(`authConfiguration.uniqueName`);
      }
      if ([5006, 5007, 5008].includes(e?.response?.data?.status ?? 0)) {
        errorMessage = t(`authConfiguration.${e.response.data.status}`);
      }

      setServerError(errorMessage);
      return;
    }
  };

  const handleDelete = async (clientId: string) => {
    await deleteConfig({ clientId });
    setDeleteModalState({ isOpen: false, clientId: '' });
  };

  const onRegerateSecret = async (watchedClientId: string) => {
    try {
      const { clientSecret } = await regenerateSecret({
        clientId: watchedClientId,
      });

      setGeneratedDataModal({
        isOpen: true,
        info: {
          clientId: '',
          clientSecret,
        },
      });
    } catch {}
  };

  return (
    <div className="authentication-page">
      <Card title={t('authConfiguration.title')}>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th className="list">{t('authConfiguration.name')}</th>
                <th className="clientId">{t('authConfiguration.clientId')}</th>
                <th className="list">
                  {t('authConfiguration.authenticationMethods')}
                </th>
                <th className="list">
                  {t('authConfiguration.authorizationGrantTypes')}
                </th>
                <th className="list">
                  {t('authConfiguration.authenticationType')}
                </th>
                <th className="list">{t('authConfiguration.maxAttempts')}</th>
                <th className="list">{t('authConfiguration.redirectUris')}</th>
                <th className="list">
                  {t('authConfiguration.postRedirectUris')}
                </th>
                <th className="list">{t('authConfiguration.scopes')}</th>
                <th className="list">{t('authConfiguration.settings')}</th>
                <th className="list">
                  {t('authConfiguration.algorithmSignToken')}
                </th>
                <th className="list">{t('authConfiguration.jwk')}</th>
                <th className="list">{t('authConfiguration.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {data?.registeredClients?.map(clientConfig => {
                const hastokenAuthAlgorithm = hasConfiguration(
                  clientConfig.settings,
                  AuthClientSettingsKeys.TOKEN_ENDPOINT_AUTH_SIGNING_ALGORITHM,
                );

                const hasJwkURL = hasConfiguration(
                  clientConfig.settings,
                  AuthClientSettingsKeys.JWK_SET_URL,
                );

                return (
                  <tr key={clientConfig.clientId}>
                    <td>
                      <span className="auth-cell-block">
                        {clientConfig.clientName ?? ''}
                      </span>
                    </td>
                    <td>
                      <span>{clientConfig.clientId}</span>
                    </td>
                    <td>
                      <ul>
                        {clientConfig.clientAuthenticationMethods?.map(
                          authenticationMethod => (
                            <li key={authenticationMethod}>
                              {authenticationMethod.replace(/_/g, ' ')}
                            </li>
                          ),
                        )}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {clientConfig.authorizationGrantTypes?.map(
                          authorizationGrantType => (
                            <li key={authorizationGrantType}>
                              {authorizationGrantType.replace(/_/g, ' ')}
                            </li>
                          ),
                        )}
                      </ul>
                    </td>
                    <td>
                      <span>{clientConfig.authenticationType ?? ''}</span>
                    </td>
                    <td>
                      <span>{clientConfig.maxAttempts ?? 1}</span>
                    </td>
                    <td>
                      <ul>
                        {clientConfig.redirectUris?.map(redirectUri => (
                          <li key={redirectUri}>{redirectUri}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {clientConfig.postLogoutRedirectUris?.map(postUri => (
                          <li key={postUri}>{postUri}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {clientConfig.scopes
                          ?.sort(
                            (a, b) =>
                              AuthClientScopesOrder[a] -
                              AuthClientScopesOrder[b],
                          )
                          .map(scope => (
                            <li key={scope}>{scope.replace(/_/g, ' ')}</li>
                          ))}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {Object.keys(clientConfig.settings)
                          .filter(
                            (key: AuthClientSettingsKey) =>
                              clientConfig.settings[key] &&
                              typeof clientConfig.settings[key] === 'boolean',
                          )
                          .map(settingsItem => (
                            <li key={settingsItem}>{settingsItem}</li>
                          ))}
                      </ul>
                    </td>
                    <td>
                      <span>
                        {hastokenAuthAlgorithm &&
                          clientConfig.settings[
                            AuthClientSettingsKeys
                              .TOKEN_ENDPOINT_AUTH_SIGNING_ALGORITHM
                          ]}
                      </span>
                    </td>
                    <td>
                      <span>
                        {hasJwkURL &&
                          clientConfig.settings[
                            AuthClientSettingsKeys.JWK_SET_URL
                          ]}
                      </span>
                    </td>
                    <td>
                      <ButtonIcon
                        variant={ButtonIconVariant.EDIT}
                        classes="auth-button-icon"
                        tooltip={t('authConfiguration.tooltipEdit')}
                        onClick={() => {
                          setModalData({
                            isOpen: true,
                            // @ts-ignore
                            formData: {
                              ...clientConfig,
                              redirectUris: clientConfig.redirectUris.map(
                                item => ({ value: item }),
                              ),
                              clientAuthenticationMethods: Object.values(
                                AuthClientMethods,
                              )?.map(a => ({
                                name: a,
                                value: a,
                                checked:
                                  clientConfig.clientAuthenticationMethods?.some(
                                    m => m === a,
                                  ),
                              })),
                              ...(clientConfig.postLogoutRedirectUris && {
                                postLogoutRedirectUris:
                                  clientConfig.postLogoutRedirectUris.map(
                                    item => ({ value: item }),
                                  ),
                              }),
                            },
                            editing: true,
                          });
                        }}
                      />
                      <ButtonIcon
                        classes="auth-button-icon"
                        variant={ButtonIconVariant.DELETE}
                        tooltip={t('authConfiguration.tooltipDelete')}
                        onClick={() =>
                          setDeleteModalState({
                            isOpen: true,
                            clientId: clientConfig.clientId,
                          })
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="card__footer">
          <Button
            label={t('authConfiguration.addNew')}
            variant="white"
            withIcon
            // @ts-ignore
            iconVariant="iconBtnAdd"
            onClick={() => {
              setModalData({
                isOpen: true,
                formData: {} as ModalConfigFormData,
                editing: false,
              });
            }}
          />
        </div>
      </Card>
      <AuthConfigurationModal
        isOpen={modalData.isOpen}
        onRequestClose={() => {
          setModalData({
            isOpen: false,
            formData: {} as ModalConfigFormData,
            editing: false,
          });
          setServerError('');
        }}
        serverError={serverError}
        formData={modalData.formData}
        editing={modalData.editing}
        handleFormSubmit={submitAuthConfigModal}
        onRegenerateSecret={onRegerateSecret}
        isRegeneratingSecret={isPending}
      />
      <ConfirmDeleteModal
        isOpen={deleteModalState.isOpen}
        handleDelete={() => {
          handleDelete(deleteModalState.clientId);
        }}
        closeModal={() => setDeleteModalState({ isOpen: false, clientId: '' })}
      />
      <GeneratedDataModal
        isOpen={generatedDataModal.isOpen}
        info={generatedDataModal.info}
        closeModal={() =>
          setGeneratedDataModal({
            isOpen: false,
            info: { clientId: '', clientSecret: '' },
          })
        }
      />
    </div>
  );
}

export { Authentication };
export default Authentication;
