import {
  AuthClientSettings,
  AuthClientSettingsKey,
} from '../types/authClientConfig';

export const hasConfiguration = (
  settings: AuthClientSettings,
  key: AuthClientSettingsKey,
) => {
  return Object.keys(settings ?? {}).some(
    (k: AuthClientSettingsKey) => k === key,
  );
};
