import { useMutation, useQueryClient } from '@tanstack/react-query';

import { client } from '../http/api';

async function deleteClient({ clientId }) {
  const response = await client.delete<any>(`/auth/client?client_id=${clientId}`, {
    withCredentials: true,
  });
  return response.data;
}

function useDeleteClient() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: deleteClient,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['Clients'] });
    },
  });
}

export { useDeleteClient };
export default useDeleteClient;
