import { useQuery } from '@tanstack/react-query';

import { client } from '../http/api';
import { WorkflowBasicInfo } from '../types/workflow';


interface WorkflowsInfoResponse {
  workflows: WorkflowBasicInfo[];
}

type GetWorkflowsParams = {
  clientOrganization: string;
}

async function getWorkflows({ clientOrganization }: GetWorkflowsParams) {
  const response = await client.get<WorkflowsInfoResponse>(`/auth/domain/workflows`, {
    params: {
      clientOrganization,
    },
    withCredentials: true,
  });
  return response.data;
}

function useWorkflowsInfo({ clientOrganization }: GetWorkflowsParams) {
  return useQuery({
    queryKey: ['Workflows', clientOrganization],
    queryFn: () => getWorkflows({ clientOrganization }),
    retry: 1,
  });
}

export { useWorkflowsInfo };
export default useWorkflowsInfo;
