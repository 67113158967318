import React, { useState, useRef } from 'react';
import { Link } from '@tanstack/react-router';
import { Text, Icon, Logo } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';

import { CLIENT_ID, AUTHORIZED, ORG_ID } from '../../constants/constants';
import { useEventListener, useLogout } from '../../hooks';
// @ts-ignore
import { ReactComponent as NavClose } from '../../images/nav-close.svg';
// @ts-ignore
import { ReactComponent as Menu } from '../../images/nav-menu.svg';

import './SideNavigation.scss';

const clientId = localStorage.getItem(CLIENT_ID);

const SideNavigation = () => {
  const { t } = useTranslation();
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(true);
  const formRef = useRef<HTMLFormElement>(null);
  const { data, mutate: logout } = useLogout();

  const toggleSideNav = () => {
    setIsToggled(!isToggled);
  };

  useEventListener('scroll', () => {
    if (window.scrollY > 10) {
      setIsShow(false);
    } else {
      setIsShow(true);
    }
  });

  const onLogout = () => {
    logout(null, {
      onSuccess: () => {
        setTimeout(() => {
          localStorage.removeItem(AUTHORIZED);
          localStorage.removeItem(ORG_ID);
          localStorage.removeItem(CLIENT_ID);
          formRef.current?.submit();
        }, 500);
      },
    });
  };

  return (
    <div className={`side-nav-wrp ${isToggled ? 'responsive' : ''}`}>
      <NavClose className="close-nav" alt="close" onClick={toggleSideNav} />
      <div
        className={`menuNav ${isToggled ? 'hide' : 'show'}`}
        onClick={toggleSideNav}
        style={{ visibility: isShow ? 'visible' : 'hidden' }}
      >
        <Menu className="menu-nav" alt="menu" />
        <Text variant="dark">Menu</Text>
      </div>
      <div className="inner-container">
        <div className="logo">
          <Logo isInverted={false} />
        </div>
        <div className="container-links">
          <div className="nav-links">
            <ul className="links">
              <li>
                <Link
                  to="/"
                  activeProps={{ className: 'active', 'aria-current': 'page' }}
                >
                  <Icon name="iconUsers" />
                  <Text>{t('sidebar.autentication')}</Text>
                </Link>
              </li>
              <li>
                <Link
                  to="/trusted-origins"
                  activeProps={{ className: 'active', 'aria-current': 'page' }}
                >
                  <Icon name="iconUrl" />
                  <Text>{t('sidebar.trustedOrigins')}</Text>
                </Link>
              </li>
              <li>
                <Link
                  to="/client-registration"
                  activeProps={{ className: 'active', 'aria-current': 'page' }}
                >
                  <Icon name="iconUsers" />
                  <Text>{t('sidebar.clientRegistration')}</Text>
                </Link>
              </li>
            </ul>
          </div>
          <div className="user">
            <div className="user-info">
              <button className="logout" onClick={onLogout}>
                <Text>{t('sidebar.logout')}</Text>
              </button>

              <form
                action={`https://oidc-stage-us.stage.incodetest.com/connect/logout`}
                method="POST"
                encType="application/x-www-form-urlencoded"
                hidden
                ref={formRef}
              >
                <input
                  type="text"
                  id="id_token_hint"
                  name="id_token_hint"
                  value={data}
                  readOnly
                />
                <input
                  type="text"
                  id="post_logout_redirect_uri"
                  name="post_logout_redirect_uri"
                  value={`${window.location.origin}/log-in`}
                  readOnly
                />
                <input
                  type="text"
                  id="client_id"
                  name="client_id"
                  value={clientId}
                  readOnly
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SideNavigation };
export default SideNavigation;
