export const AUTH_DATA = 'authData';
export const TOKEN_DATA = 'tokenData';
export const ORG_ID = 'org_id';
export const CLIENT_ID = 'client_id';
export const AUTHORIZED = 'authorized';

export enum ErrorCode {
  BAD_REQUEST = 400,
  REQUEST_ENTITY_TOO_LARGE = 413,
  USER_ALREADY_EXISTS = 4006,
  FACE_NOT_FOUND = 4019,
  TOO_MANY_RECORDS = 4021,
  FLOW_NAME_EXISTS = 4030,
  INVALID_FLOW_ID = 4031,
  WORKFLOW_NAME_EXISTS = 4040,
  WORKFLOW_ROOT_NODE_NOT_SINGLE = 4042,
  WORKFLOW_CONDITION_NODE_NOT_COMPLETE = 4044,
  WORKFLOW_GRAPH_NOT_CONNECTED = 4046,
  WORKFLOW_RESULT_NODE_NOT_FINISH = 4047,
  ORGANIZATION_NAME_EXISTS = 4050,
  NON_UNIQUE_CLIENT_NAME = 5009,
};
