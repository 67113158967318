import { useQuery } from '@tanstack/react-query';

import { client } from '../http/api';
import { FlowsBasicInfo } from '../types/flows';


interface FlowsInfoResponse {
  flows: FlowsBasicInfo[];
}

type GetFlowsParams = {
  clientOrganization: string;
}

async function getFlows({ clientOrganization }: GetFlowsParams) {
  const response = await client.get<FlowsInfoResponse>(`/auth/domain/flows`, {
    params: {
      clientOrganization,
    },
    withCredentials: true,
  });
  return response.data;
}

function useFlowsInfo({ clientOrganization }: GetFlowsParams) {
  return useQuery({
    queryKey: ['Flows', clientOrganization],
    queryFn: () => getFlows({ clientOrganization }),
    retry: 1,
  });
}

export { useFlowsInfo };
export default useFlowsInfo;
