import { useMutation, useQueryClient } from '@tanstack/react-query';

import { client } from '../http/api';

async function deleteClient({ registrationId }) {
  const response = await client.delete<any>(`/auth/registration?registrationId=${registrationId}`, {
    withCredentials: true,
  });
  return response.data;
}

function useDeleteRegistrationClient() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: deleteClient,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['ClientRegistration'] });
    },
  });
}

export { useDeleteRegistrationClient };
export default useDeleteRegistrationClient;
