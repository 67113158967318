import { useMutation } from '@tanstack/react-query';

import { client } from '../http/api';

async function logout() {
  const response = await client.post<{ idToken: string }>('session/logout')
  const token = response.data.idToken;
  return token;
}

function useLogout() {
  return useMutation({
    mutationFn: logout,
  });
}

export { useLogout };
export default useLogout;
