import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Input, Checkbox, Select } from '@incodetech/ui';

import {
  AuthClientSettingsKeys,
  AUTH_SIGNING_ALGORITHM_OPTIONS,
} from '../../../types/authClientConfig';

const AuthConfigurationSettings = ({ name }: { name: string }) => {
  const { register } = useFormContext();
  const { t } = useTranslation();

  const inputName = `settings.${name}`;

  if (name === AuthClientSettingsKeys.JWK_SET_URL) {
    return (
      <div className="input-config">
        <Input
          type="text"
          id={inputName}
          label={t(`authConfiguration.form.${name}`)}
          {...register(inputName)}
        />
      </div>
    );
  }

  if (name === AuthClientSettingsKeys.TOKEN_ENDPOINT_AUTH_SIGNING_ALGORITHM) {
    return (
      <div className="input-config">
        <Select
          id={inputName}
          {...register(inputName)}
          placeholder="Select an option"
          variant="default"
          label={t(`authConfiguration.form.${name}`)}
          options={Object.values(AUTH_SIGNING_ALGORITHM_OPTIONS).map(j => ({
            value: j,
            label: j,
          }))}
        />
      </div>
    );
  }

  return (
    <div className="input-group">
      <Checkbox label={name} id={inputName} {...register(inputName)} />
    </div>
  );
};

export default AuthConfigurationSettings;
