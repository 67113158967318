import React from 'react';
import { ModalConfirm, Text } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';

interface ConfirmDeleteRegistrationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleDelete: () => void;
}

const ConfirmDeleteRegistrationModal: React.FC<
  ConfirmDeleteRegistrationModalProps
> = ({ isOpen, closeModal, handleDelete }) => {
  const { t } = useTranslation();
  return (
    <ModalConfirm
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={t('clientRegistration.modalDelete.title')}
      handleConfirm={handleDelete}
      denyButtonLabel={t('clientRegistration.modalDelete.denyButton')}
      confirmButtonLabel={t('clientRegistration.modalDelete.confirmButton')}
      isDestructive
    >
      <Text tag="p">{t('clientRegistration.modalDelete.text')}</Text>
    </ModalConfirm>
  );
};

export default ConfirmDeleteRegistrationModal;
