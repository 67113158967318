import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Input, ButtonIconVariant, ButtonIcon } from '@incodetech/ui';

const AuthConfigurationInput = ({
  name,
  label,
  errorMessage,
  required = false,
}: {
  name: string;
  label: string;
  errorMessage: string;
  required?: boolean;
}) => {
  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const fieldErrors: any[] = ((errors ?? {})[name] as any) ?? [];

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const values = watch(name);

  return (
    <div className="input-wrapper">
      {fields.map((item, index) => {
        const fieldName = `${name}.${index}.value`;
        const value = watch(`${name}.${index}.value`);
        return (
          <div className="form-row" key={item.id}>
            <div className="form-column">
              <div className="redirect-uris">
                <Input
                  id={item.id}
                  defaultValue={value}
                  isDynamic
                  variant="full"
                  label={label}
                  {...register(fieldName, {
                    required: required && index === 0 ? errorMessage : false,
                  })}
                  error={fieldErrors[0] && fieldErrors[0].value?.message}
                />
                {index > 0 ? (
                  <ButtonIcon
                    variant={ButtonIconVariant.DELETE}
                    onClick={() => remove(index)}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
      {values[values?.length - 1]?.value ? (
        <ButtonIcon
          variant={ButtonIconVariant.ADD}
          onClick={() => append({ value: '' })}
        />
      ) : null}
    </div>
  );
};

export default AuthConfigurationInput;
