import React, { useState } from 'react';
import { Card, ButtonIcon, ButtonIconVariant, Button } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';

import {
  useGetTrustedOrigins,
  useDeleteTrustedOrigin,
  useCreateTrustedOrigin,
  useUpdateTrustedOrigin,
} from '../../hooks';
import {
  ModalDelete,
  TrustedOriginsModalType,
} from '../../types/trustedOrigins';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import TrustedOriginsModal from './components/TrustedOriginsModal';

import './TrustedOrigins.scss';

function TrustedOrigins() {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState<TrustedOriginsModalType>({
    isOpen: false,
    formData: { id: undefined, name: '', origin: '', isDefault: false },
    editing: false,
  });
  const [deleteModalState, setDeleteModalState] = useState<ModalDelete>({
    isOpen: false,
    originId: '',
  });
  const { data } = useGetTrustedOrigins();
  const { mutateAsync: addTrustedOrigin } = useCreateTrustedOrigin();
  const { mutateAsync: editTrustedOrigin } = useUpdateTrustedOrigin();
  const { mutateAsync: deleteTrustedOrigin } = useDeleteTrustedOrigin();

  const handleDelete = async (originId: string) => {
    await deleteTrustedOrigin({ originId });
    setDeleteModalState({ isOpen: false, originId: '' });
  };

  const handleFormSubmit = async (
    data: TrustedOriginsModalType['formData'],
  ) => {
    try {
      modalData.editing
        ? await editTrustedOrigin({
            ...data,
          })
        : await addTrustedOrigin({
            ...data,
          });

      setModalData({
        isOpen: false,
        formData: { id: undefined, name: '', origin: '', isDefault: false },
        editing: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="trusted-origins-page">
      <Card title={t('trustedOrigins.title')}>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th className="list">{t('trustedOrigins.id')}</th>
                <th className="list">{t('trustedOrigins.name')}</th>
                <th className="list">{t('trustedOrigins.origin')}</th>
                <th className="list">{t('trustedOrigins.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {data?.trustedOrigins?.map(origin => {
                return (
                  <tr key={origin.id}>
                    <td>
                      <span>{origin.id}</span>
                    </td>
                    <td>
                      <span>{origin.name}</span>
                    </td>
                    <td>
                      <span>{origin.origin}</span>
                    </td>
                    <td>
                      {origin.isDefault ? null : (
                        <>
                          <ButtonIcon
                            variant={ButtonIconVariant.EDIT}
                            classes="auth-button-icon"
                            tooltip={t('authConfiguration.tooltipEdit')}
                            onClick={() => {
                              setModalData({
                                isOpen: true,
                                formData: {
                                  ...origin,
                                },
                                editing: true,
                              });
                            }}
                          />
                          <ButtonIcon
                            classes="auth-button-icon"
                            variant={ButtonIconVariant.DELETE}
                            tooltip={t('authConfiguration.tooltipDelete')}
                            onClick={() => {
                              setDeleteModalState({
                                isOpen: true,
                                originId: origin.id,
                              });
                            }}
                          />
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="card__footer">
          <Button
            label={t('authConfiguration.addNew')}
            variant="white"
            withIcon
            // @ts-ignore
            iconVariant="iconBtnAdd"
            onClick={() => {
              setModalData({
                isOpen: true,
                formData: {
                  id: undefined,
                  name: '',
                  origin: '',
                  isDefault: false,
                },
                editing: false,
              });
            }}
          />
        </div>
      </Card>
      <ConfirmDeleteModal
        isOpen={deleteModalState.isOpen}
        handleDelete={() => {
          handleDelete(deleteModalState.originId);
        }}
        closeModal={() => setDeleteModalState({ isOpen: false, originId: '' })}
      />
      <TrustedOriginsModal
        isOpen={modalData.isOpen}
        onClose={() => {
          setModalData({
            isOpen: false,
            formData: { id: undefined, name: '', origin: '', isDefault: false },
            editing: false,
          });
        }}
        handleFormSubmit={handleFormSubmit}
        formData={modalData.formData}
      />
    </div>
  );
}

export { TrustedOrigins };
export default TrustedOrigins;
