import { useQuery } from '@tanstack/react-query';

import { client } from '../http/api';
import { TrustedOrigins } from '../types/trustedOrigins';

type TrustedOriginsResponse = {
  trustedOrigins: TrustedOrigins[];
};

async function getTrustedOrigins() {
  const response = await client.get<TrustedOriginsResponse>(`/auth/origin/all`, {
    withCredentials: true,
  });
  return response.data;
}

function useGetTrustedOrigins() {
  return useQuery({
    queryKey: ['TrustedOrigins'],
    queryFn: getTrustedOrigins,
    retry: 1,
  });
}

export { useGetTrustedOrigins };
export default useGetTrustedOrigins;
