import { useMutation, useQueryClient } from '@tanstack/react-query';

import { client } from '../http/api';

async function deleteTrustedOrigin({ originId }) {
  const response = await client.delete<any>(`/auth/origin?origin_id=${originId}`, {
    withCredentials: true,
  });
  return response.data;
}

function useDeleteTrustedOrigin() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: deleteTrustedOrigin,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['TrustedOrigins'] });
    },
  });
}

export { useDeleteTrustedOrigin };
export default useDeleteTrustedOrigin;
