import React from 'react';
import { ModalDialog, Button, Input } from '@incodetech/ui';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TrustedOriginsModalType } from '../../../types/trustedOrigins';

import '../../Authentication/components/AuthConfigurationModal.scss';

type FormData = TrustedOriginsModalType['formData'];
interface TrustedOriginsModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleFormSubmit: SubmitHandler<FormData>;
  formData: FormData;
}

const defaulFormValue: FormData = {
  id: undefined,
  name: '',
  origin: '',
  isDefault: false,
};

const TrustedOriginsModal: React.FC<TrustedOriginsModalProps> = ({
  isOpen,
  onClose,
  handleFormSubmit,
  formData,
}) => {
  const { t } = useTranslation();
  const methods = useForm<FormData>({
    values: { ...defaulFormValue, ...formData },
  });

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;

  const afterClose = () => {
    reset();
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      closeModal={onClose}
      afterModalClose={afterClose}
      shouldCloseOnOverlayClick={false}
      modalTitle={t('trustedOrigins.form.title')}
      classes="auth-configuration-modal"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(data => {
            handleFormSubmit(data);
            clearErrors();
          })}
          className="configuration-form"
        >
          <div className="auth-configuration-content">
            <Input
              id="name"
              label={t('trustedOrigins.form.name')}
              variant="full"
              {...register('name', {
                required: t('trustedOrigins.form.requiredField'),
              })}
              error={errors.name?.message}
            />
          </div>

          <div className="auth-configuration-content">
            <Input
              id="name"
              label={t('trustedOrigins.form.origin')}
              variant="full"
              {...register('origin', {
                required: t('trustedOrigins.form.requiredField'),
              })}
              error={errors.origin?.message}
            />
          </div>

          <div className="separator" />
          <div className="button-container">
            <Button
              label={t('trustedOrigins.form.buttonSave')}
              type="submit"
              variant="blue"
              classes="modal-button"
            />
          </div>
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default TrustedOriginsModal;
